/**
* Custom styling written on top of base template
*/

// https://video-react.js.org/ css styling
@import "~video-react/styles/scss/video-react";

.table-text {
  margin-bottom: 0px;
}

.results-page-section {
  padding-top: 0px;
}

.results-page-thumbnail-img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.deceased-notice-div {
  position: relative;
  display: inline-block;
}

.deceased-notice-div .deceased-text {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%; /* Adjust this value to move the positioned div up and down */
  background: rgba(0, 0, 0, 0.5);
  //font-family: Arial, sans-serif;
  color: red;
  width: 80%; /* Set the width of the positioned div */
}

.tree-img {
  margin: 0 auto;
  max-width: 150px;
  max-width: 150px;
  width: auto;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  // box-shadow: 0 0 0 3px #aaa;
}

$blue-dk: #002a50;
$blue: #22659c;

@mixin shadow($level: 1) {
  @if $level == 1 {
    box-shadow: 0 1px 3px rgba(black, 0.12), 0 1px 2px rgba(#f96332, 0.24);
  } @else if $level == 2 {
    box-shadow: 0 3px 6px rgba(black, 0.16), 0 3px 6px rgba(#f96332, 0.23);
  } @else if $level == 3 {
    box-shadow: 0 10px 20px rgba(black, 0.19), 0 6px 6px rgba(#f96332, 0.23);
  } @else if $level == 4 {
    box-shadow: 0 14px 28px rgba(black, 0.25), 0 10px 10px rgba(#f96332, 0.22);
  } @else if $level == 5 {
    box-shadow: 0 19px 38px rgba(black, 0.3), 0 15px 12px rgba(#f96332, 0.22);
  }
}

.tree-user {
  text-decoration: none;
  color: #666;
  //font-family: arial, verdana, tahoma;
  display: inline-block;
  padding: 10px 10px;
  transition: all 0.5s;
  background: #fff;
  max-width: 200px;
  border-radius: 6px;

  @include shadow(4);
}

.tree-user-name {
  padding-top: 5px;
  font-weight: bold;
}

.highlighted-leopard {
  background: lighten(#f96332, 35%);
  color: $blue-dk;
  box-shadow: inset 0 0 0 5px lighten(#f96332, 5%), 0 3px 6px rgba(0, 0, 0, 0.8),
    0 3px 6px rgba(0, 0, 0, 0.23);
  img {
    box-shadow: 0 0 0 1px lighten(#f96332, 5%);
  }
}

//////////////GOOGLE MAP///////////////////
.google-map-container {
  height: 90vh;
  width: 100%;
  z-index: 1;
}

/////// GOOGLE MAP MARKER ///////

.hovicon {
  display: inline-block;
  font-size: 45px;
  line-height: 90px;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  text-decoration: none;
  z-index: 1;
  color: #fff;
}

.hovicon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.hovicon:before {
  speak: none;
  font-size: 48px;
  line-height: 90px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  display: block;
  -webkit-font-smoothing: antialiased;
}
/* Effect 1 */
.hovicon.effect-1 {
  background: rgba(255, 0, 0, 0.6);
  -webkit-transition: background 0.2s, color 0.2s;
  -moz-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s;
}
.hovicon.effect-1:after {
  top: -7px;
  left: -7px;
  padding: 7px;
  box-shadow: 0 0 0 4px red;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -webkit-transform: scale(0.8);
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(0.8);
  opacity: 0;
}
/* Effect 1a */
.hovicon.effect-1.sub-a:hover {
  background: rgba(255, 0, 0, 1);
  color: red;
}
.hovicon.effect-1.sub-a:hover:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

//////////////// LEAFLET /////////////////////

.leaflet-container {
  width: 100%;
  height: 90vh;
  z-index: 1;
}

// to make the options list visible over the map
.territory-leopard-select {
  z-index: 2;
}

////////////////////////////////////////?

//////////////// Behaviour Modal ////////////
.video-card-description {
  padding-left: 5px;
}

.result-document-inner {
  height: auto;
  width: 100%;
}

.modal-body-no-border {
  padding-top: 10px;
  padding-right: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  line-height: 0;
}

.model-fullscreen {
  min-width: 80vw;
  min-height: 80vh;
}

.model-close-button {
  right: 10px !important;
  top: 10px !important;
}

.modal-header-no-padding {
  padding-top: 10px !important;
}

.modal-backdrop.show {
  opacity: 0.9 !important;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.8) !important;
}

.result-document-inner-pdf {
  min-height: 80vh;
  min-width: 80vw;
}

////////////////////////////////////////////

.touch-navbar-back-btn {
  position: fixed;
  width: 100px;
  height: 100px;
  left: 40px;
  top: 40px;
  // background-color: #f96332;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  display: inline-block;
  z-index: 9999;
  box-shadow: 3px 2px 3px #666;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.touch-navbar-back-btn:hover {
  box-shadow: 3px 2px 4px #f96332;
  // box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 0px #00d7c3;
  // border: 3px solid #00d7c3;
}

.touch-navbar-back-btn-icon {
  margin-top: 15px;
  // display: block;
}

.touch-navbar-back-btn-icon-focus {
  margin-top: 15px;
  // display: block;
  color: #f96332;
}

.leopard-search-result-thumb {
  margin: 0 auto;
  // max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: 200px;
  // border-radius: 50%;
  object-fit: cover;
  // box-shadow: 0 0 0 3px #aaa;
}

.search-no-results-image-container {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.search-no-results-image {
  max-width: 500px;
  opacity: 0.6;
}

.body-large-text-for-center {
  font-size: 18px !important;
}

.dwc-parks-image-thumbnail {
  align-self: center;
  width: 100%;
  max-height: 170px;
}

.dwc-parks-image {
  align-self: center;
  width: 100%;
}

.dwc-parks-view-button {
  text-align: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  //display: none;
}

/* Hide scrollbar for IE and Edge */
* {
  // -ms-overflow-style: none;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.text-center-verticle {
  display: flex;
  align-items: center;
}

.div-content-center {
  text-align: center;
  align-self: center;
}

.image-crop-div-background {
  background-color: #242525;
}

.wizard-checkbox-checked {
  color: #79d70f;
}

.landing-header-logo {
  max-width: 30px;
  //margin-bottom: 40px;
  //margin-top: 40px;
}

.match-leopard-your-img {
  max-width: 400px;
}

.carousal-no-shadow {
  box-shadow: none !important;
}

.match-leopard-slider-container {
  display: flex;
  justify-content: center;
  max-width: 80%;
  margin: auto;
}

.match-leopard-sidebyside-column {
  width: 500px;
  // white-space: nowrap;
  overflow-x: scroll;
}

.match-leopard-sidebyside-img {
  float: left;
  height: 500px;
  object-fit: cover;
}

.match-leopard-sidebyside-bottom-right-text {
  position: absolute;
  right: 16px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1.5em;
  line-height: 1.45em;
  padding-top: 15px;
  padding-bottom: 15px;
}

.match-leopard-sidebyside-top-left-confidence {
  position: absolute;
  padding-left: 15px;
  padding-right: 10px;
  font-size: 1.2em;
  line-height: 1.45em;
  padding-top: 15px;
}

.switch-width-control {
  font-size: 9px;
  width: 50px;

  &.rc-switch-checked:after {
    left: 30px;
  }
  &.rc-switch {
    width: 50px;
  }
}

.family-tree-wrapper {
  overflow-x: auto;
  padding-bottom: 30px;
}

.family-tree-litter-text {
  // border: 8px #fa764b solid;
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  // border-radius: 5px;
  //background-color: #fa764b;
  color: white;
}

/* Family Tree Litter Colors */

.family-tree-legend-container {
  overflow: hidden;
}

.family-tree-legend-ul {
  list-style-type: none;
}

.family-tree-legend {
  width: 7px;
  height: 7px;
  display: inline-block;
}

/* litter colors */

$litter-1: #a3cb38;
$litter-2: #d980fa;
$litter-3: #ffc312;
$litter-4: #006266;
$litter-5: #12cbc4;

.family-tree-legend-l1 {
  background-color: $litter-1;
  border: 8px $litter-1 solid;
  border-radius: 5px;
}
.family-tree-legend-l2 {
  background-color: $litter-2;
  border: 8px $litter-2 solid;
  border-radius: 5px;
}
.family-tree-legend-l3 {
  background-color: $litter-3;
  border: 8px $litter-3 solid;
  border-radius: 5px;
}
.family-tree-legend-l4 {
  background-color: $litter-4;
  border: 8px $litter-4 solid;
  border-radius: 5px;
}
.family-tree-legend-l5 {
  background-color: $litter-5;
  border: 8px $litter-5 solid;
  border-radius: 5px;
}

// .family-tree-border-highlight-l1 {border: $litter-1 solid 3px;}
// .family-tree-border-highlight-l2 {border: $litter-2 solid 3px;}
// .family-tree-border-highlight-l3 {border: $litter-3 solid 3px;}
// .family-tree-border-highlight-l4 {border: $litter-4 solid 3px;}
// .family-tree-border-highlight-l5 {border: $litter-5 solid 3px;}

/* 

  Homepage notification prompt styles
  Codepen : https://codepen.io/debugger89/pen/WNRKvpO

*/
.popover-notification-box {
  width: 0%;

  $white: #fcfcfc;
  $gray: #cbcdd3;
  $dark: #777777;
  $success: #2da8ff;
  $secondary: #13293a;

  // h1 {
  //   font-size: 0.9em;
  //   font-weight: 100;
  //   letter-spacing: 3px;
  //   padding-top: 5px;
  //   color: #fcfcfc;
  //   padding-bottom: 5px;
  //   text-transform: uppercase;
  // }

  .green {
    color: darken(#2da8ff, 20%);
    font-size: calc(2vmin);
  }

  button,
  .dot {
    cursor: pointer;
  }

  #success-box {
    position: absolute;
    height: 25%;
    max-width: 30%;
    max-height: 100%;
    bottom: 2%;
    right: 1%;
    align-content: center;
    background: linear-gradient(to bottom right, #2da8ff 40%, #213544 100%);
    border-radius: 20px;
    perspective: 40px;
    opacity: 0.8;
    z-index: 999;
  }

  #success-box:hover {
    opacity: 0.9;
    transition: opacity 0.4s, visibility 0.4s;
  }

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 4%;
    right: 4%;
  }

  .face {
    position: absolute;
    width: 20%;
    height: 20%;
    color: $white;
    top: 12%;
    left: 44%;
    z-index: 2;
    animation: bounce 1s ease-in infinite;
  }

  .shadow {
    position: absolute;
    width: 10%;
    height: 3%;
    opacity: 0.5;
    background: $dark;
    left: 45%;
    top: 38%;
    border-radius: 50%;
    z-index: 1;
  }

  .scale {
    animation: scale 1s ease-in infinite;
  }

  .message {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 40%;
    top: 40%;
    color: white;
  }

  .message-header-size {
    font-size: calc(3vmin);
  }

  .message-description-size {
    font-size: calc(2vmin);
  }

  .bottomless {
    margin-bottom: unset;
  }

  .button-box {
    position: absolute;
    background: $white;
    width: 50%;
    height: 12%;
    border-radius: 20px;
    bottom: 10px;
    left: 25%;
    outline: 0;
    border: none;
    box-shadow: 2px 2px 10px rgba($dark, 0.5);
  }

  @keyframes scale {
    50% {
      transform: scale(0.7);
    }
  }

  @keyframes bounce {
    50% {
      transform: translateY(-10px);
    }
  }
}

// Leopards Home page navigation panel icons
.home-navigation-panel-icons {
  font-size: xxx-large;
  color: #f96332;
}

.home-navigation-panel-wrapper {
  text-align: center;
}

.containerVideo {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 66.66%; /* 3:2 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.stepper-font-class {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.orientation-card {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}

.orientation-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12),
    0 4px 8px rgba(250, 122, 80, 0.534);
}

.orientation-card-selected {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px #fa7a50;
  border: 2px solid #fa7a50;
  border-radius: 5px;
}

.low-result-overlay {
  position: absolute;
  z-index: 10;
  top: inherit;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.9;
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.low-result-overlay p {
  width: 100%;
  word-wrap: break-word;
  padding: 50px;
  margin: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  color: white;
}

.label-for-lt-naming {
  margin-bottom: 0%;
  display: inline;
}