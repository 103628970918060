.clear-filter[filter-color="blue"] {
  @include linear-gradient(rgba($black-color,.20), rgba(3, 161, 224, 0.3));
}
[data-background-color="blue"]{
    background-color: $info-color;
}
.section-nucleo-icons .icons-container i:nth-child(6) {
  color: $info-color;
}
.page-header{
  &.page-header-small{
    min-height: 20vh;
    max-height: 200px;
  }
  &.page-header-small-profile-page{
    min-height: 50vh;
    max-height: 200px;
  }
  
}
