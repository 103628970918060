.tab-header-view {
  background-color: transparent !important;
  border-bottom: none;
}

.tab-header-OR {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.upload-success-message {
  width: 100%;
  height: 100%;

  h5 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  h6 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  i {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(18, 161, 18);
  }
}
